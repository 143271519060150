import saasApp from "../../routes/app.js";

<style>

/*center the buttons*/
button {
  display: block;
  margin: 0 auto;
  margin-top: 20px;
}

/*style the buttons*/
button {
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 5px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
}

/*hover effect*/
button:hover {
  background-color: #45a049;
}

</style>


<360-saas-main>
    <div style=component.getContainerStyle()>
      <h1>Selecteer optie</h1>
      <button style=component.getPaginaButtonStyle() on-click("goToPage")>Pagina's</button>
      <button style=component.getTabellenaButtonStyle() on-click("goToBuilder")>Tabellen</button>
    </div>
</360-saas-main>

class { 

    goToPage() {
        window.location.href = "/page";
    }   

    goToBuilder() {
        window.location.href = "/builder";
    }    

  getPaginaButtonStyle() {
    return {
      backgroundColor: "#8DC63F",
      color: "white",
      border: "none",
      padding: "5px 10px",
      textAlign: "center",
      textDecoration: "none",
      display: "inline-block",
      fontSize: "16px",
      margin: "4px 2px",
      cursor: "pointer",
      width: "400px"
    };
  }

  getTabellenaButtonStyle() {
    return {
      backgroundColor: "#8781BD",
      color: "white",
      border: "none",
      padding: "5px 10px",
      textAlign: "center",
      textDecoration: "none",
      display: "inline-block",
      fontSize: "16px",
      margin: "4px 2px",
      cursor: "pointer",
       width: "400px"
    };
  }

    getContainerStyle() {
        return {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "50vh"
        };
    }
}
